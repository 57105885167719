.video-background {
  position: fixed;
  top: 70px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -99;
  width: 100%;
  height: 100%;
}
.video-foreground,
.video-background iframe {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
}

.blackOverlay {
  transition: opacity 0.5s;
  background-color: black;
  opacity: 1;
  width: 20000px;
  height: 3300%;
  position: fixed;
  z-index: 3;
}
.blackOverlay.hide {
  opacity: 0;
  pointer-events:none;
}