.mainDocuments {
    margin-top: 0px;
    padding-top: 125px;
    padding-bottom: 50px;
    display: flex;
    height: 1000px;
    flex-wrap: wrap;
    margin-left: 0;
    padding-left: 100px;
    background-color: #0000005e;
    background-image: url("https://i.imgur.com/EB7VtrY.png");
    width: 100%;
    background-size: cover;
}

.imgBlock {
    border-radius: 5px;
    position: relative;
    margin: 25px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    cursor: pointer;

    .pieceInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: -5px;

      .workTitle {
        font-size: 0.85em;
        margin: 0;
        letter-spacing: 1.2px;
        font-family: "Raleway", sans-serif;
      }

      .workDesc {
        display: block;
        white-space: wrap;
        margin-top: 10px;
        font-size: 0.6em;
        letter-spacing: 1.1px;
        width: 250px;
      }
    }

    img {
      border-radius: 5px;
      display: block;
      height: auto;
    }

    .wrap {
        position:relative;
        
      }
      
      .wrap:before {
        content:"";
        position: absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        background: rgba(255, 91, 2, 0.152);
        z-index:999;
        -webkit-box-shadow: inset 0px -2px 23px 1px rgba(252, 134, 92, 0.66); 
        box-shadow: inset 0px -2px 23px 1px rgba(252, 134, 92, 0.66);
        border-radius:  5px;
      }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 100%;
      top: 0;
      right: 0;
      overflow: hidden;
      width: 0;
      height: 125.92px;
      transition: 0.5s ease;
      border-radius: 5px;
      opacity: 0.9;
      background-color: #fe5a1d;

        h6 {
          color: black;
          font-size: 0.6em;
          margin: 0 5px;
        }
      }
    }

    .pieceInfo {
      color: white;
      font-size: 20px;
      position: absolute;
      overflow: hidden;
      top: 50%;
      left: 50%;
      width: 200px;
      text-align: center;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }

  .imgBlock:hover {
    .overlay {
      width: 100%;
      left: 0;
    }
  }