.nav {
  display: flex;
  background: black;
  justify-content: left;
  border-bottom: solid rgba(255, 255, 255, 0.116) 2px;
  padding: 15px;
  align-items: center;
  position: absolute;
  top: 0;
  height: 50px;

  .logo {
    font-family: "Raleway", sans-serif;
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 65px;
    color: gold;
    font-size: 1em;
    letter-spacing: 1px;
    text-decoration: none;
    position: absolute;
    z-index: 1000;

    img {
      opacity: 0.95;
    }
  }

  .links {
    display: flex;
    margin-left: 95px;
    width: 1200px;
    align-items: center;
    padding: 12px;

    a {
      font-family: "Raleway", sans-serif;
      margin-right: 25px;
      margin-left: 25px;
      color: #fe5a1d;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.5s;
    }

    a:hover {
      font-family: "Raleway", sans-serif;
      margin-right: 25px;
      margin-left: 25px;
      color: white;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.5s;
    }

    

  }

  .linksSmall {
    display: flex;
    margin-left: 95px;
    width: 200px;
    align-items: center;
    padding: 12px;

    a {
      font-size: 0.5em;
      font-family: "Raleway", sans-serif;
      margin-right: 15px;
      margin-left: 15px;
      color: #fe5a1d;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.5s;
    }

    a:hover {
      font-family: "Raleway", sans-serif;
      margin-right: 15px;
      margin-left: 15px;
      color: white;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.5s;
    }

    

  }
}
