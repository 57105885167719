@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.mainApp {
  display: flex;
  position: relative;
  height: 100vh;
}

.linkToVideo {
    display: flex;
    width: 100%;
    position: absolute;

    img {
        background: none;
        width: 200px;
        right: 25px;
        top: 15px;
        position: absolute;
        font-family: "Raleway", sans-serif;
        font-size: 25px;
        appearance: none;        
        border: none;
        color: #FFF;
}
}

.socialMediaIcons {
  bottom: 25px;
  left: 25px;
  position: fixed;

  i {
    color: rgba(255, 255, 255, 0.493);
    font-size: 2.5em;
    transition: 0.5s;
    padding: 10px;
  }

  i:hover {
    color: rgb(255, 255, 255);
    transition: 0.5s;
    cursor: pointer;
  }
}

.copyright {
  bottom: 5px;
  right: 25px;
  font-size: 0.8em;
  position: fixed;
  color: rgba(255, 255, 255, 0.472);
}

.mobileMain {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .mobileIcons {
  
    i {
      color: rgba(255, 255, 255, 0.493);
      font-size: 2.5em;
      transition: 0.5s;
      padding: 10px;
    }
  
    i:hover {
      color: rgb(255, 255, 255);
      transition: 0.5s;
      cursor: pointer;
    }
  }
  .mobileCopyright {
    bottom: 1px;
    font-size: 0.4em;
    position: fixed;
    color: rgba(255, 255, 255, 0.472);
  }

  img {
      width: 80%;
  }

  h1 {
      color: rgba(255, 255, 255, 0.644);
      font-size: 1.1em;
      width: 80%;
      text-align: center;
      font-family: "Raleway", sans-serif;
      letter-spacing: 1px;
  }

  .storyMobile {
    color: rgb(214, 214, 214);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    border-radius: 5px;
    height: 125px;
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    border: solid rgba(246, 175, 21, 0.205) 2px;
    font-size: 1.8em;
    margin-top: 25px;

    i {
        padding: 10px;
        font-size: 2em;
        color: white;
    }
}
.horaceStory:hover {
    background: rgba(22, 13, 0, 0.541);
    transition: 0.5s;
}
}

.play-btn {
  outline: none;
  border: none;
    width: 45px;
    height: 45px;
    right: 98px;
    top: 135px;
    cursor: pointer;
    position: absolute;
    background: radial-gradient( #fe591dad 60%, rgba(255, 255, 255, 1) 62%);
    border-radius: 50%;
    display: block;
    box-shadow: 0px 0px 25px 3px #fe591db4;
  }
  
  /* triangle */
  .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  /* pulse wave */
  .play-btn:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate1 2s;
    animation: pulsate1 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, .75);
    top: -35%;
    left: -35%;
    background: rgba(198, 16, 0, 0);
  }
  
  @-webkit-keyframes pulsate1 {
    0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
  
    }
  }
  
  @keyframes pulsate1 {
    0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
  
    }
  }

