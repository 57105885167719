.testimonials {
    margin-top: 82px;
    background-color: #000000;
    width: 100%;
    background-size: cover;
    display: flex;
    height: 100%;
    transition: 0.5s;

    i {
        font-size: 8em;
        color: white;
        margin-left: 35px;
        margin-top: 195px;
        cursor: pointer;
        transition: 0.5s;
    }
    i:hover {
        color: rgba(255, 255, 255, 0.746);
        transition: 0.5s;
    }

    .textBio {
        padding:  15px 25px;
        line-height: 25px;
        min-height: 400px;
        width: 950px;
        height: 100%;
        background: rgba(24, 19, 17, 0.513);
        margin-left: 35px;
        margin-top: 25px;
        border-radius: 5px;

        h2 {
            color: rgb(255, 255, 255);

            span {
                color: rgb(255, 139, 93);
                font-size: 0.8em;
            }
            
        }
        h3 {
            color: rgb(255, 255, 255);
            padding: 0;
            
        }

        h4 {
            color: rgba(255, 255, 255, 0.692);
            padding: 0;
            font-size: 0.8em;
            line-height: 18px;
            
        }

        p {
            color: rgb(255, 255, 255);
            
        }
    }
}